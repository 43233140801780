<template>
  <div class="page">
    <div class="page-header flex-b-c">
      <span>错误表述历史巡查</span>
      <el-button type="primary" @click="addTask">创建任务</el-button>
    </div>
    <div class="page-card">
      <el-input style="width: 15rem" v-model="secrchVal" clearable placeholder="请输入任务名称"></el-input>
      <span class="timefont" style="margin-left: 2rem;margin-right: 1rem;">任务状态</span>
      <el-select style="width: 11.875rem;" v-model="selVal" placeholder="请选择" clearable>
        <el-option label="等待中" :value="0"></el-option>
        <el-option label="进行中" :value="1"></el-option>
        <el-option label="已完成" :value="2"></el-option>
      </el-select>
      <span class="timefont" style="margin-left: 2rem">操作时间</span>
      <el-date-picker class="m-l" v-model="timeArr" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      <div class="reset" @click="reset">重置</div>
      <div class="btn" @click="search">查询</div>
    </div>
    <div class="m-t-16 m-b-16">
      本月剩余排查账号额度：{{ remainingCount }}
    </div>
    <div class="page-card">
      <el-table v-loading="loading" class="m-t" stripe :data="tableData" :header-cell-style="{
          background: '#F6F7FA',
          color: '#333',
        }" style="
          width: 100%;
          border: 0.0625rem solid #e4e6ec;
          border-bottom: none;
        ">
        <el-table-column prop="name" label="任务名称"></el-table-column>
        <el-table-column prop="totalCount" label="任务数量"></el-table-column>
        <el-table-column prop="createTime" label="任务创建时间"></el-table-column>
        <el-table-column prop="createUser" label="操作人员"></el-table-column>
        <el-table-column label="任务状态">
          <template slot-scope="scope">
            <span style="width: 0.625rem;height: 0.625rem;border-radius: 50%;display: inline-block;"
                  :style="scope.row.status == 0 ? 'background: #D8D8D8' : scope.row.status == 1 ? 'background: #2E59EC' : 'background: #02BC7C'"></span>
            <span :style="scope.row.status == 0 ? 'color: #666666' : scope.row.status == 1 ? 'color: #2E59EC' : 'color: #02BC7C'">
              {{ scope.row.status == 0 ? '等待中' : scope.row.status == 1 ? '进行中' : '已完成'  }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="flex-c">
              <el-link type="primary" :disabled="scope.row.status != 2" @click="downloadItem(scope.row)">
                下载
              </el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link type="danger" :disabled="scope.row.status != 2" @click="deleteItem(scope.row)">
                删除
              </el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <Pagination :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" @SizeChange="SizeChange" @CurrentChange="CurrentChange" />
    </div>
    <AddTask ref="AddTask" :remainingCount="remainingCount" @createTask="createTask"></AddTask>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import AddTask from "./addTask.vue";
import Api from "@/api/errLog/index";
export default {
  components: {
    Pagination,
    AddTask
  },
  data() {
    return {
      secrchVal: '',
      selVal: '',
      timeArr: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [
        { name: 1 }
      ],
      loading: false,
      remainingCount: 0,//剩余巡查
    }
  },
  mounted() {
    this.getList()
    this.getRemainingCount()
  },
  methods: {
    reset() {
      this.secrchVal = ''
      this.selVal = ''
      this.timeArr = []
      this.page.pageNo = 1
      this.getList()
    },
    search() {
      this.page.pageNo = 1
      this.getList()
    },
    async getList() {
      this.loading = true;
      let data = {
        page: this.page.pageNo,
        limit: this.page.pageSize
      }
      if (this.secrchVal) data.name = this.secrchVal
      if (this.selVal||this.selVal==0) data.status = this.selVal
      if (this.timeArr.length) data.startTime = this.timeArr[0]
      if (this.timeArr.length) data.endTime = this.timeArr[1]
      let res = await Api.getList(data)
      if (res?.data?.code == 200) {
        this.tableData = res?.data?.data?.list
        this.page.total = res?.data?.data?.totalCount / 1
        this.loading = false
      }
    },
    downloadItem(item) {
      const link = document.createElement('a');
      link.href = item.downloadUrl;
      link.setAttribute('download', item.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteItem(item) {
      this.$confirm('确定删除该任务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        Api.deleteItem(item.id).then(res => {
          if (res?.data?.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getList()
            this.getRemainingCount()
          }
        })
      })
    },
    // 查询剩余巡查账号数
    async getRemainingCount() {
      let res = await Api.getRemainingCount()
      this.remainingCount = res.data.data
    },

    addTask() {
      this.$refs.AddTask.dialogTableVisible = true
    },
    createTask() {
      this.getList()
      this.getRemainingCount()
    },
    SizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getList();
    },
    CurrentChange(val) {
      this.page.pageNo = val;
      this.getList();
    },
  },
}
</script>

<style lang="scss" scoped>
.reset {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
//表格内容颜色
::v-deep .el-table__body tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
::v-deep .el-table__body tbody tr:nth-child(even) td {
  background-color: #f6f7fa;
}
</style>