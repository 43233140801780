<template>
  <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose" width="45%">
    <div slot="title" class="dialog-header">
      <span>{{ title }}</span>
    </div>
    <div class="dialog-body my-scrollbar" @scroll="handleScroll" ref="scrollContainer" v-loading="btnloading" element-loading-spinner="el-icon-loading">
      <div class="flex-c m-b-24">
        <el-input v-model="searchText" :placeholder="title=='选择网站'?'请输入网站名称/网址':'请输入公众号名称'" size="normal" clearable></el-input>
        <el-select style="width: 16rem;" class="m-l-16 m-r-16" v-model="searchSel" placeholder="请选择" clearable multiple collapse-tags>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="add-btn" @click="search">搜索</div>
      </div>
      <el-table v-loading="loading" ref="table" class="m-t" stripe :data="tableData" @selection-change="handleSelectionChange" :header-cell-style="{
          background: '#F6F7FA',
          color: '#333',
        }" style="
          width: 100%;
          border: 0.0625rem solid #e4e6ec;
          border-bottom: none;">
        <el-table-column type="selection" width="120"></el-table-column>
        <el-table-column prop="accountName" label="网站名称"></el-table-column>
        <el-table-column label="网站地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: #3078FF;">{{ scope.row.homeUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bodyTypeName" label="主体类型"></el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消 </el-button>
      <el-button @click="submitDataList" :loading="btnloading" type="primary">确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
import Api from '@/api/errLog/index.js'
export default {
  props: {
    title: {
      type: String,
      default: '选择网站',
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      btnloading: false,
      searchText: '',
      searchSel: [],
      options: [],
      page: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [
      ],
      loading: false,
      selectedList: [],//勾选的数据
    };
  },
  mounted() {
    this.options = localStorage.getItem('body_type') ? JSON.parse(localStorage.getItem('body_type')) : [];
    this.options = this.options.filter(item => item.value != 5 && item.value != 62)
    this.getWebsiteList();
  },
  methods: {
    search() {
      this.page.pageNo = 1;
      this.getWebsiteList();
    },
    async getWebsiteList() {
      this.loading = true;
      let data = {
        "platformTypeList": this.title == '选择网站' ? ["1"] : ["3"],
        "bodyTypeList": ["2", "1", "3", "6", "51", "61", "16"],
        "pageNo": this.page.pageNo,
        "pageSize": this.page.pageSize,
        "statHarm": true
      }
      //如果this.searchText中包含了'www'或者'com'或者'cn'
      if (this.searchText && (this.searchText.includes('www') || this.searchText.includes('com') || this.searchText.includes('cn'))) {
        data.domainName = this.searchText
      }
      else if (this.searchText) {
        data.accountName = this.searchText
      }

      if (this.searchSel.length) data.bodyTypeList = this.searchSel
      let res = await Api.getWebsiteList(data)
      if (res?.data?.code == 200) {
        this.tableData = res?.data?.data?.list
        this.loading = false;
      }
    },
    handleScroll(event) {

      // 确保事件触发时的逻辑
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      const clientHeight = event.target.clientHeight;

      if (scrollHeight - scrollTop === clientHeight) {
        // 滚动到底部时的逻辑
        this.loadMoreData();
      }

    },
    async loadMoreData() {
      this.loading = true;
      let zancunList = [...this.selectedList]
      let data = {
        "platformTypeList": this.title == '选择网站' ? ["1"] : ["3"],
        "bodyTypeList": ["2", "1", "3", "6", "51", "61", "16"],
        "pageNo": this.page.pageNo,
        "pageSize": this.page.pageSize,
        "statHarm": true
      }
      if (this.searchText) data.accountName = this.searchText
      if (this.searchSel.length) data.bodyTypeList = this.searchSel
      let res = await Api.getWebsiteList(data)
      if (res?.data.code == 200) {
        this.tableData = this.tableData.concat(res?.data?.data?.list);
        this.page.pageNo += 1;
        this.$nextTick(() => {
          const scrollContainer = this.$refs.scrollContainer;
          let scrollTop = scrollContainer.scrollTop;
          scrollTop -= 150;
          scrollContainer.scrollTop = scrollTop;
          zancunList.forEach(row => {
            this.$refs.table.toggleRowSelection(row);
          });
          
        });
        this.loading = false;
        
      }



    },
    handleSelectionChange(val) {
      this.selectedList = val
    },
    submitDataList() {
      
      this.$emit('getSubmit', this.selectedList)
      this.handleClose()
    },
    handleClose() {
      this.dialogTableVisible = false;
      this.btnloading = false;
      // 重置数据
      this.searchText = ''
      this.searchSel = []
      this.selectedList = []
      this.$refs.table.clearSelection();
    }
  },
}
</script>

<style lang="scss" scoped>
.add-btn {
  width: 80px;
  height: 36px;
  background: #f58030;
  border-radius: 4px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.dialog-body {
  max-height: 500px;
  overflow: auto;
}
</style>